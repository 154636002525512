// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---src-pages-404-js": () => import("/Users/felix/Desktop/Portfolio/hara-kiri/src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-blog-js": () => import("/Users/felix/Desktop/Portfolio/hara-kiri/src/pages/blog.js" /* webpackChunkName: "component---src-pages-blog-js" */),
  "component---src-pages-blog-1-ramen-js": () => import("/Users/felix/Desktop/Portfolio/hara-kiri/src/pages/blog1-ramen.js" /* webpackChunkName: "component---src-pages-blog-1-ramen-js" */),
  "component---src-pages-blog-2-manger-un-ramen-js": () => import("/Users/felix/Desktop/Portfolio/hara-kiri/src/pages/blog2-manger-un-ramen.js" /* webpackChunkName: "component---src-pages-blog-2-manger-un-ramen-js" */),
  "component---src-pages-blog-3-cantine-hara-kiri-js": () => import("/Users/felix/Desktop/Portfolio/hara-kiri/src/pages/blog3-cantine-hara-kiri.js" /* webpackChunkName: "component---src-pages-blog-3-cantine-hara-kiri-js" */),
  "component---src-pages-blog-4-reference-sakes-js": () => import("/Users/felix/Desktop/Portfolio/hara-kiri/src/pages/blog4-reference-sakes.js" /* webpackChunkName: "component---src-pages-blog-4-reference-sakes-js" */),
  "component---src-pages-blog-5-nos-cinq-ramens-js": () => import("/Users/felix/Desktop/Portfolio/hara-kiri/src/pages/blog5-nos-cinq-ramens.js" /* webpackChunkName: "component---src-pages-blog-5-nos-cinq-ramens-js" */),
  "component---src-pages-blog-6-harakiri-biere-js": () => import("/Users/felix/Desktop/Portfolio/hara-kiri/src/pages/blog6-harakiri-biere.js" /* webpackChunkName: "component---src-pages-blog-6-harakiri-biere-js" */),
  "component---src-pages-desserts-js": () => import("/Users/felix/Desktop/Portfolio/hara-kiri/src/pages/desserts.js" /* webpackChunkName: "component---src-pages-desserts-js" */),
  "component---src-pages-entrees-js": () => import("/Users/felix/Desktop/Portfolio/hara-kiri/src/pages/entrées.js" /* webpackChunkName: "component---src-pages-entrees-js" */),
  "component---src-pages-index-js": () => import("/Users/felix/Desktop/Portfolio/hara-kiri/src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-info-js": () => import("/Users/felix/Desktop/Portfolio/hara-kiri/src/pages/info.js" /* webpackChunkName: "component---src-pages-info-js" */),
  "component---src-pages-mentions-js": () => import("/Users/felix/Desktop/Portfolio/hara-kiri/src/pages/mentions.js" /* webpackChunkName: "component---src-pages-mentions-js" */),
  "component---src-pages-plats-js": () => import("/Users/felix/Desktop/Portfolio/hara-kiri/src/pages/plats.js" /* webpackChunkName: "component---src-pages-plats-js" */),
  "component---src-pages-restaurant-js": () => import("/Users/felix/Desktop/Portfolio/hara-kiri/src/pages/restaurant.js" /* webpackChunkName: "component---src-pages-restaurant-js" */),
  "component---src-pages-sakes-js": () => import("/Users/felix/Desktop/Portfolio/hara-kiri/src/pages/sakes.js" /* webpackChunkName: "component---src-pages-sakes-js" */)
}

