module.exports = [{
      plugin: require('/Users/felix/Desktop/Portfolio/hara-kiri/node_modules/gatsby-plugin-smoothscroll/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('/Users/felix/Desktop/Portfolio/hara-kiri/node_modules/gatsby-plugin-web-font-loader/gatsby-browser.js'),
      options: {"plugins":[],"google":{"families":["Open Sans"]}},
    },{
      plugin: require('/Users/felix/Desktop/Portfolio/hara-kiri/node_modules/gatsby-plugin-google-analytics/gatsby-browser.js'),
      options: {"plugins":[],"trackingId":"UA-158857797-1"},
    },{
      plugin: require('/Users/felix/Desktop/Portfolio/hara-kiri/gatsby-browser.js'),
      options: {"plugins":[]},
    }]
